import styled, { css } from 'styled-components';

import StyledButton from '../ui/Button/Button';
import { above, below } from '../../styles/libs';
import Text from '../ui/Text';
import Icon from '../ui/Icon';
import theme from '../../styles/config/theme';

const breakpoint = theme.TABLET;

const desktopRowHeight = '4rem';

export const Container = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${() => theme.COLOR_GRAY_LIGHTER};
  ${() => css`
    ${above(breakpoint)`
      padding: 0;
      background-color: ${() => theme.COLOR_WHITE};
      border-bottom: solid 1px ${() => theme.COLOR_GRAY_LIGHT};
    `}
  `}
`;

export const BookingRefTitle = styled(Text)`
  padding-right: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  ${() => css`
    ${above(breakpoint)`
      display: inline-block;
      padding-left: 1.75rem;
      padding-right: 0;
      margin-right: 0.5rem;
      line-height: ${desktopRowHeight};
      font-weight: normal;
    `}
  `}
`;

export const BookingRefValue = styled(Text)`
  font-size: 1.25rem;
  ${() => css`
    ${above(breakpoint)`
      display: inline-block;
      line-height: ${desktopRowHeight};
      font-weight: bold;
    `}
  `}
`;

export const MoreInformation = styled.div`
  margin-top: 2rem;
  ${() => css`
    ${above(breakpoint)`
      position: absolute;
      margin-top: 0;
      top: 0;
      right: 1rem;
      line-height: ${desktopRowHeight};
    `}
  `}
`;

export const CloseButtonMobile = styled(StyledButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  min-height: 1.75rem;
  width: 1.75rem;
  color: ${() => theme.COLOR_WHITE};
  background-color: ${() => theme.COLOR_LAKE_GREEN_DARK};
  ${() => css`
    ${above(breakpoint)`
      display: none;
    `}
  `}
`;

export const CloseButtonDesktop = styled(StyledButton)`
  width: 100%;
  border-radius: 0;
  padding: 0 0.5rem;
  height: ${desktopRowHeight};
  color: ${() => theme.COLOR_TEXT_BODY};
  background-color: ${() => theme.COLOR_WHITE};
  border-bottom: solid 1px ${() => theme.COLOR_GRAY_LIGHT};

  &:hover,
  &:focus {
    background-color: ${() => theme.COLOR_GRAY_LIGHTER};
  }
  ${() => css`
    ${below(breakpoint)`
      display: none;
    `}
  `}
`;

export const CloseIcon = styled(Icon)`
  ${() => css`
    ${above(breakpoint)`
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    `}
  `}
`;

export default null;
