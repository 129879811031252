import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { SENTRY_ORIGINS, captureError } from '../lib/sentry';

/**
 * useQuery wrapper which reports any errors to Sentry
 */
const useVerboseQuery = (query, options) => {
  const capturedRef = useRef(false);

  // Add the handled context so the generalized
  // error handler knows we've already handled this
  const optionsWithContext = {
    ...options,
    context: {
      handled: true,
    },
  };

  const response = useQuery(query, optionsWithContext);

  if (response.error && !capturedRef.current) {
    const queryName = getOperationName(query) ?? 'unknown';
    captureError(response.error, SENTRY_ORIGINS.APOLLO, {
      query: queryName,
    });
    capturedRef.current = true;
  }

  return response;
};

export default useVerboseQuery;
