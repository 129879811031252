import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';

import dictionaryItem from '../../hocs/withDictionary/dictionaryItem';
import close from '../../static/images/icons/Close.svg';

import {
  Container,
  CloseButtonMobile,
  CloseButtonDesktop,
  CloseIcon,
  BookingRefTitle,
  BookingRefValue,
} from './BookingBasketHeader.style';

const BookingBasketHeader = ({
  bookingRef,
}) => {
  const router = useRouter();

  const listingPath = router.asPath.split('/');
  listingPath.pop();
  const closeBasketHref = listingPath.join('/');

  return (
    <Container>
      <Link href={closeBasketHref} as={closeBasketHref}>
        <CloseButtonMobile type="button" align="center" noPadding>
          <CloseIcon size="12px" icon={close} />
        </CloseButtonMobile>
      </Link>
      <Link href={closeBasketHref} as={closeBasketHref}>
        <CloseButtonDesktop type="button" align="left">
          <CloseIcon icon={close} />
          <span>Close</span>
        </CloseButtonDesktop>
      </Link>
      <BookingRefTitle size={2} dictionary={dictionaryItem('BookingBasketHeader__BookingRef')} />
      <BookingRefValue>{bookingRef}</BookingRefValue>
    </Container>
  );
};

BookingBasketHeader.propTypes = {
  bookingRef: PropTypes.string,
};

BookingBasketHeader.defaultProps = {
  bookingRef: '',
};

export default BookingBasketHeader;
