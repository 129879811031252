import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  background: ${props => props.theme.COLOR_WHITE};
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${props => props.theme.CHECKOUT_MAX_WIDTH};
  margin: 0 auto;
`;
