import styled from 'styled-components';

const ImportantInformationLink = styled.a`
  display: inline;
  font-family: "FSElliotWebRegular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
`;

export default ImportantInformationLink;
