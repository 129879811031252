import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import useVerboseQuery from '../../hooks/useVerboseQuery';

import BasketIcon from './BasketIcon';
import { basketCount } from './Basket';
import StyledBasketToggle from './BasketToggle.style';

import GET_QUOTE from '../Quote/graphql/getQuote';

import FetchPolicy from '../../constants/FetchPolicy';
import IbePropTypes from '../../IbePropTypes';

const propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
};

const defaultProps = {
  className: '',
};

const BasketToggle = ({ className, handleClick, client }) => {
  const { data: quoteData } = useVerboseQuery(GET_QUOTE, {
    fetchPolicy: FetchPolicy.CACHE_AND_NETWORK,
    client,
  });
  return (
    <StyledBasketToggle className={className} onClick={() => handleClick()}>
      <BasketIcon
        count={basketCount(quoteData?.quote)}
      />
    </StyledBasketToggle>
  );
};

BasketToggle.propTypes = propTypes;
BasketToggle.defaultProps = defaultProps;

export default withApollo(BasketToggle);
